/**
  file input styles
*/
.fileInput {
  min-height: 60px;
  --thumbnail-size: 0px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 8px 10px;
}
.fileInputShowThumbnail {
  --thumbnail-size: 60px;
}
.fileInputLabelContainer {
  padding: var(--app-spacing-xs) var(--app-spacing-small);
  width: calc(100% - 45px - var(--thumbnail-size));
}
.fileInputThumbnail {
  width: var(--thumbnail-size) !important;
  height: var(--thumbnail-size) !important;
  object-fit: contain;
}

/**
  form error styles
*/
ion-note.formError {
  font-size: var(--font-size-xs);
  margin-left: var(--app-spacing-xs);
  margin-top: var(--app-spacing-xs);
}
