.ion-page.locationPage .addBillingAddressCard {
  --border-style: dashed;
  --border-width: '2px';
  --border-color: var(--ion-color-primary);
  --background: transparent;
}
.ion-page.locationPage .addBillingAddressCard ion-label {
  font-size: var(--font-size-xl);
  color: var(--ion-color-primary);
}
