/* BEGIN loading page */
.loadingContainer {
  width: 200px;
  height: 200px;
  text-align: center;
  opacity: 0;
  margin: 0 auto;
  animation-name: fadeIn;
  animation-duration: 0.25s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: var(--smooth-ease);
}
.loadingContainer p {
  margin: 0;
  width: 100%;
}
.loadingContainer svg {
  position: relative;
  top: -30px;
  pointer-events: none;
}
/* END loading page */

/* BEGIN user profile page */
.userProfile ion-card {
  max-width: 500px;
  margin: 0 auto;
}
.userProfile ion-avatar,
.userProfileAvatar {
  width: var(--avatar-size-large);
  height: var(--avatar-size-large);
  margin: 0 auto;
  position: relative;
  font-size: var(--font-size-xl);
}
.userProfileAvatar {
  border-radius: 50%;
}
.userProfile h1 {
  position: relative;
  padding: 0 var(--app-spacing);
}
.userProfileEditButton {
  position: absolute;
  margin-left: var(--app-spacing-small);
}
/* END user profile page */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
