#formAccountApplication .swiper {
  overflow: visible;
}
#formAccountApplication .swiper .swiper-wrapper {
  transition-duration: 400ms !important;
}
#formAccountApplication .swiper-slide:not(.swiper-slide-active) {
  height: 0px;
  overflow: hidden !important;
}
#formAccountApplication .swiper-slide ion-row,
#formAccountApplication .swiper-slide form {
  width: 100%;
  text-align: left;
}
#formAccountApplication ion-list ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  width: 100%;
}
