ion-item.formInput.rounded {
  --padding-start: 0;
  --inner-padding-end: 0;
}

ion-item.formInput.ion-invalid [slot='error'],
ion-item.formInput.ion-invalid [slot='helper'] {
  display: block;
}
ion-item.formInput .fileInput + ion-note {
  padding-top: 0;
}
