ion-card.stepCard {
  --step-color: var(--ion-color-medium);
  transition: all 0.2s var(--smooth-ease);
  border: 3px solid transparent;
}
ion-card.stepCard .number {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid var(--step-color);
  background-color: transparent;
  transition: all 0.2s var(--smooth-ease);
}
ion-card.stepCard ion-card-header ion-card-title,
ion-card.stepCard ion-card-header ion-card-subtitle {
  color: var(--step-color);
}
ion-card.stepCard.active ion-card-header ion-card-title,
ion-card.stepCard.active ion-card-header ion-card-subtitle {
  font-weight: bold;
}

ion-card.stepCard.active {
  transform: scale(1.055);
  transform-origin: 50% bottom;
  border-color: rgba(var(--ion-color-primary-rgb), 0.7);
  border-width: 3px;
}

ion-card.stepCard.active {
  --background: #fff;
  --step-color: var(--ion-color-primary);
}
ion-card.stepCard.complete {
  --background: #fff;
  --step-color: var(--ion-color-success);
}
ion-card.stepCard.invalid {
  --background: #fff;
  --step-color: var(--ion-color-danger);
}
