.userCard ion-avatar,
.userCard ion-skeleton-text.avatar {
  width: var(--avatar-size-large);
  height: var(--avatar-size-large);
  margin: 0 auto;
  position: relative;
  font-size: var(--font-size-xl);
}
.userCard ion-skeleton-text.avatar {
  border-radius: 50%;
}
ion-popover.popoverUserMeta {
  --max-width: 500px;
  --min-width: 350px;
}
ion-popover.popoverUserMeta ion-list ion-item {
  --padding-start: 0;
}
