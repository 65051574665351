/* account application page */
ion-card.accountApplicationCard {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
ion-card.accountApplicationCard ion-card-title {
  font-size: 20px;
  font-weight: 400;
}

/* home page */
ion-card.homeSupplierCard {
  width: 150px;
  margin: var(--app-spacing-xs);
}
.homeSectionTitle {
  display: inline-block;
  color: black;
  font-weight: 400;
  font-size: var(--font-size-xl);
}

/* orders page */
.cardStatsContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.cardStatsContainer > ion-card {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  margin: var(--app-spacing-xs);
  width: 100%;
  transform-origin: 50% bottom;
}

/* supplier page */
.cardSupplierInfo {
  max-width: 400px;
  margin-left: auto !important;
  margin-right: auto !important;
}

/* media queries */
@media screen and (min-width: 385px) {
  .cardStatsContainer > ion-card {
    width: calc(50% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 768px) {
  .cardStatsContainer > ion-card {
    width: calc(33% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 900px) {
  .cardStatsContainer > ion-card {
    width: calc(25% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 990px) and (max-width: 1199px) {
  .cardStatsContainer > ion-card {
    width: calc(20% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .cardStatsContainer > ion-card {
    width: calc(25% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1350px) {
  ion-card.homeSupplierCard {
    width: calc(50% - var(--app-spacing-xs) * 2);
    max-width: 150px;
  }
}
@media screen and (min-width: 1300px) {
  .cardStatsContainer > ion-card {
    width: calc(20% - var(--app-spacing-xs) * 2);
  }
}
@media screen and (min-width: 1500px) {
  .cardStatsContainer > ion-card {
    max-width: 220px;
  }
}
