.popoverAccountMetaTrigger {
  --padding-start: 0;
  --padding-end: 0;
}

ion-popover.popoverAccountMeta {
  --max-width: 340px;
  --min-width: 300px;
  --min-height: 120px;
}

ion-popover.popoverAccountMeta ion-list ion-item {
  --padding-start: var(--app-spacing-small);
  --inner-padding-end: var(--app-spacing-small);
}
