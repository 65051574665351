.fileInput {
  min-height: 60px;
  --thumbnail-size: 0px;
}
.fileInput.thumbnail-visible {
  --thumbnail-size: 60px;
}
.fileInput .labelContainer {
  padding: var(--app-spacing-xs) var(--app-spacing-small);
  width: calc(100% - 45px - var(--thumbnail-size));
}
.fileInput .thumbnail {
  width: var(--thumbnail-size) !important;
  height: var(--thumbnail-size) !important;
  object-fit: contain;
}
.fileInput.rounded {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 8px 10px;
}
