.slideHeader {
  position: relative;
  margin: 0 auto;
}
.slideHeader .progressBar {
  position: absolute;
  width: 100%;
  height: 0px;
  border-bottom: 3px solid var(--background-color);
  top: 16px;
  left: 0;
}
.slideHeader .progressBar .progress {
  position: absolute;
  width: 0%;
  height: 0px;
  border-bottom: 3px solid var(--ion-color-primary);
  top: 0;
  left: 0;
  transition: width 0.3s var(--smooth-ease);
}
.slideHeader .slideSteps {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.slideHeader .step {
  --step-color: #ccc;
  color: var(--step-color);
  transition: all 0.2s ease-in;
  text-align: center;
  background-color: var(
    --ion-card-background,
    var(--ion-item-background, var(--ion-background-color, #fff))
  );
  user-select: none;
  width: 60px;
  overflow: visible;
  position: relative;
}
.slideHeader .step.clickable {
  cursor: pointer;
}
.slideHeader .step .number {
  width: 32px;
  height: 32px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--step-color);
  background-color: transparent;
  transition: all 0.2s ease-in;
}
.slideHeader .step p {
  margin: 0;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 120px;
  position: relative;
  left: calc(50% - 60px);
  height: 20px;
  transition: all 0.1s ease-in;
  font-size: var(--font-size-small);
  font-weight: bold;
}
.slideHeader .step.active {
  --step-color: var(--ion-color-primary);
}
.slideHeader .step.active p {
  /* font-weight: bold; */
}
.slideHeader .step.complete {
  /* color: var(--ion-color-primary); */
}
.slideHeader .step.complete .number {
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
  color: #fff;
}
/* .slideHeader .step:not(:first-child)::before,
.slideHeader .step:not(:first-child)::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 0px;
  border-bottom: 2px solid transparent;
  top: 20px;
  left: calc(50% - 82px);
  transition: width 0.4s ease-in;
}
.slideHeader .step:not(:first-child)::before {
  width: 50%;
  border-bottom-color: var(--background-color);
}
.slideHeader .step:not(:first-child)::after {
  border-bottom-color: var(--ion-color-primary);
}
.slideHeader .step.active:not(:first-child)::after {
  width: 50%;
} */
