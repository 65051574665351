.download-button {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  
  .download-button a p {
    background-color: gray;
    border-radius: 5px;
    padding: 5px;
    font-size: 10px;
    cursor: pointer;
    color: white; /* Ensures text is readable */
    text-align: center; /* Centers text within the button */
  }
  
  .upload-thumbnail {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  
  .viewer-container {
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative; /* Ensures positioning context for absolute elements */
  }
  