ion-footer.pageActions ion-toolbar {
  padding-left: 16px;
  padding-right: 16px;
}

.ion-page.pageLayout ion-content ion-grid.pageLayoutGrid {
  max-width: 1400px;
}
.ion-page.pageLayout > ion-footer ion-toolbar .toolbar-container {
  max-width: 1380px;
  margin: 0 auto;
}
.ion-page.pageLayout > ion-footer .footerContainer {
  max-width: 1380px;
  margin: 0 auto;
}

@media (max-width: 575px) {
  .ion-page.pageLayout ion-content.ion-padding {
    --ion-padding: 5px;
  }
}
