.dataTable {
  color: var(--ion-text-color);
}
.dataTable .tableControls {
  padding: 5px 16px;
}
.dataTable table {
  width: 100%;
  text-rendering: optimizeLegibility;
  border-color: var(--border-color);
  position: relative;
}

.dataTable table.loading {
  opacity: 0.8;
  user-select: none;
  pointer-events: none;
}
.dataTable table.loading thead tr th {
  padding-bottom: var(--app-spacing-xs);
  border-width: 0;
}

.dataTable th {
  font-weight: 500;
  color: black;
  font-size: var(--font-size-default);
}
.dataTable th.sortable {
  cursor: pointer;
  user-select: none;
}
.dataTable th .headerContainer > ion-icon {
  transition: all 0.15s var(--smooth-ease);
  opacity: 0;
  color: var(--ion-color-medium);
}
.dataTable th.sortable:hover .headerContainer > ion-icon {
  opacity: 0.4;
}
.dataTable th.sortable.sorted .headerContainer > ion-icon {
  opacity: 1;
  color: var(--ion-color-primary);
}
.dataTable th.sort-desc .headerContainer > ion-icon {
  transform: rotate(180deg);
}
.dataTable th .headerContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}
.dataTable th.left .headerContainer {
  justify-content: flex-start;
}
.dataTable th.center .headerContainer {
  justify-content: center;
}
.dataTable th.right .headerContainer {
  justify-content: flex-end;
}
.dataTable th.left .headerContainer > span {
  order: 1;
}
.dataTable th.left .headerContainer > ion-icon {
  order: 2;
  margin-left: var(--app-spacing-xs);
}
.dataTable th.right .headerContainer > span {
  order: 2;
}
.dataTable th.right .headerContainer > ion-icon {
  order: 1;
  margin-right: var(--app-spacing-xs);
}
.dataTable th:first-child,
.dataTable tr td:first-child:not(.mobileRow) {
  padding-left: 16px;
}
.dataTable th:last-child,
.dataTable tr td:last-child:not(.mobileRow) {
  padding-right: 16px;
}

.dataTable th,
.dataTable td:not(.mobileRow) {
  padding: var(--app-spacing-small);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: calc(9px);
}

.dataTable th.left,
.dataTable td.left {
  text-align: left;
}
.dataTable th.center,
.dataTable td.center {
  text-align: center;
}
.dataTable th.right,
.dataTable td.right {
  text-align: right;
}

.dataTable tr.clickable {
  cursor: pointer;
}

.dataTable tr td {
  display: table-cell;
  vertical-align: middle;
  min-height: 48px;
}
.dataTable tbody tr:nth-child(odd) {
  background-color: var(--background-color);
}
.dataTable tbody tr:hover {
  background-color: #eee;
}

.dataTableActionsPopover {
  --width: 250px;
}
.dataTableActionsPopover ion-list ion-item > ion-text {
  display: flex;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
  font-size: 1.6em;
  width: 1em;
  height: 1em;
}

.dataTableActionsPopover ion-list ion-item > ion-text .svg-inline--fa {
  max-width: 100%;
}

.dataTable p {
  margin-bottom: 0;
  margin-top: 0;
}

.dataTable ion-searchbar {
  height: 37px;
  padding-left: 0;
  padding-right: 0;
}
.dataTable .tableTitle {
  display: inline-block;
  color: black;
  font-weight: 400;
  font-size: var(--font-size-xl);
}

/* BEGIN Mobile styles */

.dataTable table.mobile tbody tr {
  display: flex;
  flex-direction: column;
}
.dataTable table.mobile tr:first-child {
  border-top: 1px solid var(--border-color);
}
.dataTable table.mobile tr {
  border-bottom: 1px solid var(--border-color);
}
.dataTable table.mobile tbody tr td.mobileRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-small);
}
.dataTable table.mobile tbody tr td.mobileRow .mobileHeader {
  font-weight: 500;
  color: black;
}
.dataTable table.mobile tbody tr td.mobileRow .mobileContent {
  text-align: right;
}

.dataTable td.mobileRow {
  padding: var(--app-spacing-xs);
}
.dataTable td.mobileRow > * {
  padding: var(--app-spacing-xs);
}
/* END Mobile styles */

/* BEGIN Other components inside DataTable styles */
.dataTable ion-input {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: #fff;
}
.dataTable ion-input input {
  padding-left: var(--app-spacing-small) !important;
  padding-top: var(--app-spacing-xs) !important;
  padding-bottom: var(--app-spacing-xs) !important;
}
/* END Other components inside DataTable styles */
