/* FormLicenseInput.module.css */

  /* PDF Thumbnail */

  .pdfContainer {
    display: flex;
    flex-direction: column; 
    align-items: center;
  }
  
  .pdfThumbnail {
    height: 30px;
    width: 30px;
    object-fit: contain;
    cursor: pointer;
  }
  
  .pointerCursor {
    cursor: pointer;
  }

