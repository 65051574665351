.inputCartQuantity .inputCartQuantityButton {
  --padding-start: 0px;
  --padding-end: 0px;
}

.inputCartQuantity ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: transparent;
  --background-hover: transparent;
}
