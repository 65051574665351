.supplierLogo,
.swiper .swiper-slide img.supplierLogo {
  height: 60px;
  width: 100%;
  max-width: 200px;
  object-fit: contain;
  object-position: center;
}

.supplierLogo.clickable {
  cursor: pointer;
}
